<template>
  <!-- v-if="token" -->
  <div>
    <unAuthorized />
    <errorNetwork />

    <!-- ==== DIALOG KONFIRMASI LOGOUT ==== -->
    <v-dialog v-model="dialog.logout" persistent max-width="450">
      <v-card class="border-radius box-shadow">
        <v-card-text style="height: 400px">
          <v-img
            src="@/assets/images/logout.png"
            class="ml-auto mr-auto"
            max-width="500"
          >
          </v-img>
          <p class="headline font-weight-bold text-center">
            Yaaahh &#128553;
            <!-- <v-icon class="ml-2">mdi-emoticon-frown-outline</v-icon> -->
          </p>
          <p class="mt-5 body-2 font-weight-regular text-center">
            Yakin kamu ingin keluar ?
          </p>
        </v-card-text>
        <v-card-actions class="pb-5 mx-3">
          <v-spacer></v-spacer>
          <v-btn
            small
            depressed
            width="100"
            color="#d31145"
            :loading="process.run"
            :disabled="process.run"
            class="white--text text-capitalize"
            @click="logout"
          >
            <v-icon color="#fff" small class="mr-1">mdi-check-circle</v-icon>
            Ya
          </v-btn>
          <v-btn
            small
            outlined
            width="100"
            color="red"
            :disabled="process.run"
            class="text-capitalize"
            @click="dialog.logout = false"
          >
            <v-icon color="red" small class="mr-1">mdi-close-circle</v-icon>
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== NAVBAR ATAS ==== -->
    <v-app-bar
      fixed
      app
      style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)!important;"
      :color="$vuetify.theme.dark ? 'dark' : 'white'"
      elevation="0">

      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'md'"
        @click.stop="drawer = !drawer"/>
      <v-toolbar-title :class="$vuetify.breakpoint.name === 'xs' ? '' : 'ml-3'">
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="text-left mr-3">
        <v-menu
          bottom
          offset-y
          origin="center center"
          transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              dense
              v-bind="attrs"
              v-on="on"
              class="py-0 pt-0 pb-0 pl-1 my-0">
              <v-list-item-content class="py-0 mt-0" style="width: 250px;">
                <v-list-item-title class="black--text pt-0 text-end mr-4 body-2">
                 {{ user.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar
                class="my-0"
                :style="
                $vuetify.breakpoint.name === 'xs' ? 
                  'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(223, 224, 235);'
                : 
                  'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(223, 224, 235);'
                "
              >
                <v-img :src="require('@/assets/logo/soda.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0 justify-center"
                      align="center">
                      <v-progress-circular indeterminate color="#d31145">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-list-item-avatar>
              <v-list-item-icon :class="$vuetify.breakpoint.name === 'xs' ? 'ml-0' : ''">
                <v-icon color="#d31145" right>mdi-menu-down</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
          <v-list dense class="py-0">
            <div v-for="(item, i) in menu" :key="i">
              <v-list-item @click="actionsMenu(item.action)" class="c-p">
                <v-list-item-icon>
                  <v-icon :color="item.color" right>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <!-- ==== NAVIGATION DRAWER BAGIAN SAMPING KIRI ==== -->
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      fixed
      app
      v-if="$route.name !== '/login'">
      <v-list-item class="px-2 mb-5" style="margin-bottom: 1px">
        <v-list-item-avatar>
          <v-img :src="require('@/assets/logo/soda.png')"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">Internal SODAPOS</v-list-item-title>
          <v-list-item-subtitle class="font-weight-bold caption">sodapos mobile new</v-list-item-subtitle>
        </v-list-item-content>
        <v-btn
          icon
          @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <!-- <v-divider class="mb-5 mt-1"></v-divider> -->
      <v-list
        dense
        nav
        v-for="item in menu_items"
        :key="item.title"
        style="color:#d31145"
        class="py-1">
        <v-list-item
          link
          :disabled="item.disabled"
          v-if="item.hasParent === 0"
          :to="item.href"
          :value="item.href.split('/')[1] === comparePath.split('/')[1] ? true : false">
          <v-list-item-icon class="mr-3">
            <v-tooltip right>
              <template v-slot:activator="{on, attrs}">
                <v-icon size="25" v-on="on" v-bind="attrs">{{ item.icon }}</v-icon>
              </template>
              <span style="color:#fff">{{ item.text }}</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
              <v-chip 
                v-if="item.text_info"
                label
                color="#d3114595"
                class="white--text ml-4"
                x-small>
                {{ item.text_info }}
              </v-chip>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          no-action
          style="color:#d31145"
          color="#d31145"
          v-model="item.action"
          v-if="item.hasParent === 1"
          :value="item.href.split('/')[0] === comparePath.split('/')[0] ? true : false">
          <template v-slot:activator>
            <v-list-item-icon class="mr-3">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </template>
          <v-list-item
            v-for="(g, index) in item.submenu"
            :key="index"
            :to="g.href"
            link
            class="pl-7">
            <v-list-item-icon class="mr-3">
              <v-icon>{{ g.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ g.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import unAuthorized from "../components/401.vue";
import errorNetwork from "../components/500.vue";
import store from "@/store";
export default {
  components: {
    unAuthorized,
    errorNetwork,
  },
  data(vm) {
    return {
      comparePath: "",
      model: null,
      drawer: true,
      darkMode: false,
      mini: false,
      menu: [
        {
          title: "Logout",
          icon: "mdi-logout",
          color: "#D31145",
          action: "logout"
        },
      ],
      detail: {},
      dialog: {
        logout: false,
        confirmation: false,
        dialogNotifications: false,
        selectStore: false,
        store_now: false,
        upgradeInfo: false,
      },
      process: {
        run: false,
      },
    };
  },
  watch: {
    $route(to, from) {
      if (from.matched.length > 0) {
        this.comparePath = this.$route.path;
        if (to.path == from.matched[0].path) {
          this.$router.push(from.path);
        }
      }
    },
  },
  computed: {
    errorNetwork() {
      return this.$store.state.common.errorNetwork;
    },
    user() {
      // this.detail = JSON.parse(TokenService.getUser());
      return JSON.parse(TokenService.getUser())
    },
    menu_items() {
      return [
        {
          text: 'Dashboard',
          text_info: "",
          icon: 'mdi-view-dashboard-outline',
          href: '/',
          disabled: false,
          hasParent: 0,
        },
        {
          text: 'Invoice',
          text_info: "",
          icon: 'mdi-clipboard-text-outline',
          href: '/invoice',
          disabled: false,
          hasParent: 0,
        },
        {
          text: 'Merchant',
          text_info: "",
          icon: 'mdi-card-account-details-outline',
          href: '/merchant',
          disabled: false,
          hasParent: 0,
        },
        {
          text: 'Outlet & Dompet',
          text_info: "",
          icon: 'mdi-storefront-outline',
          href: '/store',
          disabled: false,
          hasParent: 1,
          submenu: [
            {
              text: 'Outlet',
              text_info: "",
              icon: 'mdi-storefront-outline',
              href: '/store',
              disabled: false,
              hasParent: 0,
            },
            {
              text: 'Pendapatan Dana',
              text_info: "",
              icon: 'mdi-wallet-outline',
              href: '/store-wallet',
              disabled: false,
              hasParent: 0,
            },
            {
              text: 'Penarikan Dana',
              text_info: "",
              icon: 'mdi-cash-multiple',
              href: '/store-wallet-withdraw',
              disabled: false,
              hasParent: 0,
            },
          ]
        },
        {
          text: 'Paket/Promo/Serial',
          text_info: "",
          icon: 'mdi-tag-outline',
          href: '/package-info',
          disabled: false,
          hasParent: 1,
          submenu: [
            {
              text: 'Paket Langganan',
              text_info: "",
              icon: 'mdi-tag-outline',
              href: '/package-info',
              disabled: false,
              hasParent: 0,
            },
            // {
            //   text: 'Paket Kredit Poin',
            //   text_info: "",
            //   icon: 'mdi-circle-multiple-outline',
            //   href: '/package-store-credit',
            //   disabled: false,
            //   hasParent: 0,
            // },
            {
              text: 'Promo Sales',
              text_info: "",
              icon: 'mdi-tag-text-outline',
              href: '/promo-sales',
              disabled: false,
              hasParent: 0,
            },
            {
              text: 'Serial Aktivasi',
              text_info: "",
              icon: 'mdi-tag-text-outline',
              href: '/serial-activation',
              disabled: false,
              hasParent: 0,
            },
          ]
        },
        {
          text: 'Settings',
          text_info: "",
          icon: 'mdi-cogs',
          href: '/account-sales',
          disabled: false,
          hasParent: 1,
          submenu: [
            {
              text: 'Template Whatsapp',
              text_info: "",
              icon: 'mdi-whatsapp',
              href: '/whatsapp-template',
              disabled: false,
              hasParent: 0,
            },
            {
              text: 'Send Promotion',
              text_info: "",
              icon: 'mdi-email-outline',
              href: '/promotion',
              disabled: false,
              hasParent: 0,
            },
          ],
        },
        {
          text: 'Sales & Komisi',
          text_info: "",
          icon: 'mdi-account-cog',
          href: '/account-sales',
          disabled: false,
          hasParent: 1,
          submenu: [
            {
              text: 'Sales Account',
              text_info: "",
              icon: 'mdi-account-tie',
              href: '/account-sales',
              disabled: false,
              hasParent: 0,
            },
            {
              text: 'Komisi',
              text_info: "",
              icon: 'mdi-clipboard-text-outline',
              href: '/commission',
              disabled: false,
              hasParent: 0,
            },
            {
              text: 'Penarikan Komisi',
              text_info: "",
              icon: 'mdi-cash-multiple',
              href: '/withdraw',
              disabled: false,
              hasParent: 0,
            },
          ]
        },
        {
          text: 'Pemasukan Dana SODA',
          text_info: "",
          icon: 'mdi-cash',
          href: '/income',
          disabled: false,
          hasParent: 1,
          submenu: [
            {
              text: 'Ringkasan',
              text_info: "",
              icon: 'mdi-clipboard-text-outline',
              href: '/income/summary',
              disabled: false,
              hasParent: 0,
            },
            {
              text: 'Pesan Ditempat',
              text_info: "",
              icon: 'mdi-clipboard-text-outline',
              href: '/income/order',
              disabled: false,
              hasParent: 0,
            },
            {
              text: 'Pesan Antar',
              text_info: "",
              icon: 'mdi-clipboard-text-outline',
              href: '/income/delivery',
              disabled: false,
              hasParent: 0,
            },
          ]
        },
        {
          text: 'Franchise/Wh',
          text_info: "",
          icon: 'mdi-home-group',
          href: '/franchise',
          disabled: false,
          hasParent: 0,
        },
        {
          text: 'Pesan Masuk',
          text_info: "",
          icon: 'mdi-email',
          href: '/message',
          disabled: false,
          hasParent: 0,
        },
        // {
        //   text: 'Migrasi Database (SUPERUSER)',
        //   text_info: "",
        //   icon: 'mdi-database-arrow-up',
        //   href: '/migration',
        //   disabled: this.user.id == 1 ? false : true,
        //   hasParent: 0,
        // },
        {
          text: 'Internal Account',
          text_info: "",
          icon: 'mdi-account',
          href: '/account',
          disabled: false,
          hasParent: 0,
        },
      ]
    },
    token() {
      return this.$store.state.token;
    },
  },
  created() {
  },
  mounted() {
    if (this.token) {
      var date1 = new Date("06/30/2019");
      var date2 = new Date("07/30/2019");

      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      // this.fetchCountNotification();
      // this.getEvent()
    }
  },
  methods: {
    async fetchNotification() {},
    async fetchCountNotification() {},
    storeNow() {
      this.dialog.store_now = true;
      this.storeReponse = "same store";
    },
    update(selected) {
      localStorage.setItem('event_selected', JSON.stringify(selected))
      this.dialog.selectStore = false;
      window.location.reload();
    },
    async getEvent() {
      this.process.run = true
      await get(`eo/event`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.data_event = res.results.data
          localStorage.setItem("event", JSON.stringify(res.results.data))
        }
      })
    },
    convert(item) {
      return item.replace(/&lt;/g, "<");
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    loading() {
      window.location.reload();
    },
    actionsMenu(action) {
      if (action == "logout") {
        this.logout();
      }

    },
    async logout() {
      this.process.run = true;
      await post(`internal/dashboard/logout`, {})
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.clearLocalStorage()
        }else {
          this.clearLocalStorage()
        }
      }).catch((error) => {
        this.clearLocalStorage()
      })
    },
    clearLocalStorage() {
      this.process.run = false;
      TokenService.removeToken();
      localStorage.clear();
      window.location = "/login";
    }
  },
};
</script>

<style>
.c-p {
  cursor: pointer;
}
</style>
