import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { TokenService } from './service/Storage.Service'

import vuetify from './plugins/vuetify'
import Convert from './plugins/Convert'
import VeeValidate from './plugins/VeeValidate'
import VueApexCharts from "./plugins/VueApexCharts"
import VCurrencyField from "./plugins/VCurrencyField"
import VueCtkDateTimePicker from './plugins/VueCtkDateTimePicker'


import '@/assets/css/main.css'

if (TokenService.getToken()) {
  store.commit("setToken", TokenService.getToken())
  store.commit("setUser", JSON.parse(TokenService.getUser()))
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  Convert,
  vuetify,
  VeeValidate,
  VueApexCharts,
  VCurrencyField,
  VueCtkDateTimePicker,
  render: h => h(App)
}).$mount('#app')
