import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js"
import Login from "../views/public/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Masuk",
      all: false, 
      public: true,
      onlyLoggedOut: true,
    }
  },
  {
    path: "/",
    name: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Dashboard.vue"),
    meta: {
      title: "Dashboard",
      all: false, 
      public: false,
    }
  },
  {
    path: "/invoice",
    name: "invoice",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Invoice.vue"),
    meta: {
      title: "Invoice",
      all: false, 
      public: false,
    }
  },
  {
    path: "/merchant",
    name: "merchant",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Merchant.vue"),
    meta: {
      title: "Merchant",
      all: false, 
      public: false,
    }
  },
  {
    path: "/store",
    name: "store",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Store.vue"),
    meta: {
      title: "Outlet",
      all: false, 
      public: false,
    }
  },
  {
    path: "/store-wallet",
    name: "store-wallet",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/StoreWallet.vue"),
    meta: {
      title: "Wallet Outlet",
      all: false, 
      public: false,
    }
  },
  {
    path: "/store-wallet-withdraw",
    name: "store-wallet-withdraw",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/StoreWalletWithdraw.vue"),
    meta: {
      title: "Withdraw Wallet Outlet",
      all: false, 
      public: false,
    }
  },
  {
    path: "/store-detail/:id",
    name: "store detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/StoreDetail.vue"),
    meta: {
      title: "Detail Outlet",
      all: false, 
      public: false,
    }
  },
  {
    path: "/whatsapp-template",
    name: "Template Message",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/TemplateWhatsapp.vue"),
    meta: {
      title: "Template Message",
      all: false, 
      public: false,
    }
  },
  {
    path: "/package-info",
    name: "Paket Info",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/PackageInfo.vue"),
    meta: {
      title: "Paket Info",
      all: false, 
      public: false,
    }
  },
  // {
  //   path: "/package-store-credit",
  //   name: "Paket Kredit Poin",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/private/PackageCreditPoint.vue"),
  //   meta: {
  //     title: "Paket Kredit Poin",
  //     all: false, 
  //     public: false,
  //   }
  // },
  {
    path: "/promo-sales",
    name: "Sales Promo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Promo.vue"),
    meta: {
      title: "Sales Promo",
      all: false, 
      public: false,
    }
  },
  {
    path: "/promo-sales/detail-code/:id",
    name: "Detail Kode Promo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/private/PromoDetailCode.vue"),
    meta: {
      title: "Detail Kode Promo",
      all: false, 
      public: false,
    }
  },
  {
    path: "/serial-activation",
    name: "Serial Aktivasi",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/SerialActivation.vue"),
    meta: {
      title: "Serial Aktivasi",
      all: false, 
      public: false,
    }
  },
  {
    path: "/serial-activation/detail-serial/:id",
    name: "Detail Nomor Serial Aktivasi",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/private/SerialActivationDetail.vue"),
    meta: {
      title: "Detail Nomor Serial Aktivasi",
      all: false, 
      public: false,
    }
  },
  {
    path: "/promotion",
    name: "Promotion",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Promotion.vue"),
    meta: {
      title: "Promotion",
      all: false, 
      public: false,
    }
  },
  {
    path: "/franchise",
    name: "Franchise/Wh",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Franchise.vue"),
    meta: {
      title: "Franchise/Wh",
      all: false, 
      public: false,
    }
  },
  {
    path: "/commission",
    name: "komisi",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Commission.vue"),
    meta: {
      title: "Komisi",
      all: false, 
      public: false,
    }
  },
  {
    path: "/withdraw",
    name: "penarikan-komisi",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Withdraw.vue"),
    meta: {
      title: "Penarikan Komisi",
      all: false, 
      public: false,
    }
  },
  {
    path: "/message",
    name: "message",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Messages.vue"),
    meta: {
      title: "Pesan Masuk",
      all: false, 
      public: false,
    }
  },
  {
    path: "/income/summary",
    name: "Ringkasan Pemasukan Dana",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Income/IncomeSummary.vue"),
    meta: {
      title: "Ringkasan Pemasukan Dana",
      all: false, 
      public: false,
    }
  },
  {
    path: "/income/order",
    name: "Pemasukan Dana",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Income/Order.vue"),
    meta: {
      title: "Pemasukan Dana",
      all: false, 
      public: false,
    }
  },
  {
    path: "/income/delivery",
    name: "Pemasukan Dana",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Income/Delivery.vue"),
    meta: {
      title: "Pemasukan Dana",
      all: false, 
      public: false,
    }
  },
  {
    path: "/migration",
    name: "migration",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/Migration.vue"),
    meta: {
      title: "Migrasi Database",
      all: false, 
      public: false,
    }
  },
  {
    path: "/account-sales",
    name: "sales-account",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/SalesAccount.vue"),
    meta: {
      title: "Sales Account",
      all: false, 
      public: false,
    }
  },
  {
    path: "/account",
    name: "account",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/private/InternalAccount.vue"),
    meta: {
      title: "Internal Account",
      all: false, 
      public: false,
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const authenticated = store.state.authenticated
  const isAll = to.matched.some((record) => record.meta.all)
  const onlyLoggedOut = to.matched.some((record) => record.meta.onlyLoggedOut)
  const isPublic = to.matched.some((record) => record.meta.public)
  
  
  if (!isAll && !isPublic && !authenticated) {
    return next({
      path: "/login"
    })
  }
  if (authenticated && onlyLoggedOut) {
    return next("/")
  }
  document.title = `SODA Internal | ${to.meta.title}`
  next()
})

export default router;
